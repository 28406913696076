
import {
  Component,
  Prop,
  Watch,
  Vue,
} from 'vue-property-decorator';
import { AutocompleteItem } from '@/components/commonComponents/Chip.vue';
import { FetchResult } from 'apollo-link';
import { FetchPolicy } from 'apollo-client';
import { assignmentToAutocomplete } from '@/helpers/mappers/assignable-users-mapper';
import eventBus from '@/event-bus';
import Message from '@/components/mixins/Message.vue';

// Lazy loading imports
const Chip = () => import(
  /* webpackChunkName: "chip" */
  /* webpackPrefetch: false */
  '@/components/commonComponents/Chip.vue'
);

interface User {
  id: number,
  username?: string,
}

interface AssignToItem {
  id: number,
  label: string,
  'label_color': string,
  'label_text_color': string,
  users: Array<User>,
}

interface Permissions {
  updatable?: boolean,
  updatableCustom?: boolean,
  creatable?: boolean,
  creatableCustom?: boolean,
  removable?: boolean,
}

@Component({
  name: 'Assignments',
  components: {
    Chip,
  },
})
export default class Assignments extends Vue {
  @Prop({ default: () => [] })
  private readonly assigned!: AssignToItem;

  @Prop({ default: () => [] })
  private readonly assignable!: AssignToItem;

  @Prop()
  private readonly dossierId?: number;

  @Prop()
  private readonly dossierTypeSlug?: string;

  @Prop()
  private readonly navigationSlug?: string;

  @Prop({ default: () => [] })
  private readonly itemCount!: number;

  @Prop({
    default: () => ({
      updatable: false,
      updatableCustom: false,
      creatable: true,
      creatableCustom: true,
      removable: true,
    }),
  })
  private readonly permissions!: Permissions;

  protected selectableItems: Array<AutocompleteItem> = [];

  private loading = false;

  @Watch('loading')
  private emitLoading() {
    this.$emit('update:loading', this.loading);
  }

  private fetchAutocompleteItems(fetchPolicy: FetchPolicy = 'network-only'): void {
    const variables = {
      assignId: this.assignable.id,
    };

    this.loading = true;
    import('@/graphql/queries/dossier-assignable-users')
      .then(({ default: query }) => this.$apollo.query({
        query,
        fetchPolicy,
        variables,
      }))
      .then((response) => response.data.dossierAssignableUsers.map(assignmentToAutocomplete))
      .then((tags: Array<AutocompleteItem>) => {
        this.selectableItems = tags;
      })
      .catch((error) => {
        Message.error(this.$t('generic.error.occurred'));
        throw error;
      })
      .finally(() => {
        this.loading = false;
      });
  }

  private removeAssignmentAction(userId: number): void {
    this.loading = true;

    this.unlinkAssignment(userId)
      .catch((error) => {
        Message.error(this.$t('modules.assign.unassignToDossier.failure'));
        throw error;
      })
      .finally(() => {
        Message.success(this.$t('modules.assign.unassignToDossier.success'));
        this.loading = false;
        eventBus.$emit('initializeForceRerender');
      });
  }

  private addAssignmentAction(userId: number): void {
    this.loading = true;

    this.addAssignment(userId)
      .catch((error) => {
        Message.error(this.$t('modules.assign.assignToDossier.failure'));
        throw error;
      })
      .finally(() => {
        this.loading = false;
        Message.success(this.$t('modules.assign.assignToDossier.success'));
        eventBus.$emit('initializeForceRerender');
      });
  }

  private addAssignment(userId: number): Promise<FetchResult> {
    const variables = {
      dossierId: this.dossierId,
      dossierTypeSlug: this.dossierTypeSlug,
      navigationSlug: this.navigationSlug,
      assignId: this.assignable.id,
      userId,
    };

    return new Promise((resolve, reject) => import('@/graphql/mutations/assign-to-dossier')
      .then(({ default: mutation }) => this.$apollo.mutate({
        mutation,
        variables,
      }))
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      }));
  }

  private unlinkAssignment(userId: number): Promise<FetchResult> {
    const variables = {
      dossierId: this.dossierId,
      dossierTypeSlug: this.dossierTypeSlug,
      navigationSlug: this.navigationSlug,
      assignId: this.assigned.id,
      userId,
    };

    return new Promise((resolve, reject) => import('@/graphql/mutations/unassign-from-dossier')
      .then(({ default: mutation }) => this.$apollo.mutate({
        mutation,
        variables,
      }))
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      }));
  }
}
