interface ChipItem {
  id: number,
  text: string,
  textColor?: string,
  chipColor?: string,
  prefix?: string,
}

interface OutgoingDTO extends ChipItem {
  disabled?: boolean,
  divider?: boolean,
  header?: string,
}

interface IncomingDTO {
  id: number,
  username: string,
}

const assignmentToAutocomplete = (
  {
    id, username,
  }: IncomingDTO,
): OutgoingDTO => ({
  id,
  text: `${username}`,
});

export { assignmentToAutocomplete, IncomingDTO, OutgoingDTO };
